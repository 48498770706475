import {getDataContactUs} from "../../services/services";

export const changeLanguage = lang => {
    return {type: 'SET_LANGUAGE', payload: lang}
}
export const initContactUsData = () => {
    return async dispatch => {
        try {
            const {data} = await getDataContactUs()
            await dispatch({type: 'INIT_CONTACTUS', payload: data.data})

        } catch (err) {
            console.log(err)
        }
    }
}