import PageLayout from "../../../layouts/PageLayout/PageLayout";
import { useState, useEffect } from 'react'
import { useSelector } from "react-redux";
import Loading from "../../../components/Loading/Loading";
import parse from "html-react-parser";
import { getTrasnferbetween } from "../../../services/services";

const Trasnferbetween = () => {
    //variables
    const [loading, setLoading] = useState(true)
    const [content, setContent] = useState()
    const currentLanguage = useSelector(state => state.currentLanguage)

    const initData = async () => {
        setLoading(true)
        try {
            const { data } = await getTrasnferbetween()
            if (data.success) {
                await setContent(data.data)
                setLoading(false)
            }
        } catch (err) {
            setLoading(false)
            console.log(err)
        }
    }
    useEffect(() => {
        initData()
    }, [])
    return (
        <PageLayout title="Trasnferbetween" classNames='publicBackground'>
            {loading ? <Loading /> : parse(currentLanguage === 'en' ? content.text_en : content.text_fa)}
        </PageLayout>
    )
}
export default Trasnferbetween