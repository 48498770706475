import styles from './PageLayout.module.scss'
import {useTranslation} from "react-i18next";
import {changeTitle} from "../../helper/functions";

const PageLayout = ({children, title, classNames}) => {

    //variables
    const {t} = useTranslation()
    //functions
    changeTitle(t(title))

    return (
        <section className={`${styles.container_page} ${classNames}`}>
            <div className="inside">
                <p className={styles.title}>{t(title)}</p>
                <div className={styles.content}>
                    {children}
                </div>
            </div>
        </section>
    )
}
export default PageLayout