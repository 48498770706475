import http from "./http";
import axios from 'axios';
import i18next from 'i18next';

export const getCountries = () => {
    return http.get("/country-list")
}
export const getFAQs = () => {
    axios.defaults.headers.common["X-localization"] = i18next.language.toString();
    return http.get("/faqs")
}
export const getDataContactUs = () => {
    return http.get(`/connect-with-us/index`)
}
export const getReviews = () => {
    axios.defaults.headers.common["X-localization"] = i18next.language.toString();
    return http.get(`/review`)
}
export const getRules = () => {
    return http.get("/terms")
}
export const getAboutUs = () => {
    return http.get("/about")
}
export const getAustraliaToIran = () => {
    return http.get("/remittance/australia_to_iran")
}
export const getIranToAustralia = () => {
    return http.get("/remittance/iran_to_australia")
}
export const getTrasnferbetween = () => {
    return http.get("/remittance/trasnferbetween")
}
export const getAllCountry = () => {
    return http.get("/country-list")
}
export const calculatorCurrency = (from, to, value) => {
    axios.defaults.headers.common["X-localization"] = i18next.language.toString();
    return http.get(`/calculator-currency?country_from=${from}&country_to=${to}&value=${value}`)
}
export const getRates = () => {
    axios.defaults.headers.common["X-localization"] = i18next.language.toString();
    return http.get(`/show-remittance-rate`);
};
export const currencyPairs = () => {
    return http.get(`/currency-pairs`)
}
export const registerSpecialPrice = (objUser) => {
    axios.defaults.headers.post["X-localization"] = i18next.language.toString();
    return http.post(`/special-price`, objUser);
};
export const getSpecialRates = () => {
    return http.get(`/rate`)
}
export const getSlider = () => {
    return http.get(`/sliders`)
}
export const getWhyUs = () => {
    return http.get(`/why-us`)
}