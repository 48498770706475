import styles from './Home.module.scss'
import {lazy} from 'react'
import News from "../../components/News/News";
import {changeTitle} from "../../helper/functions";
import {useTranslation} from "react-i18next";
import MarqueeSection from "../../components/MarqueeSection/MarqueeSection";

const BannerHome = lazy(() => import("../../components/BannerHome/BannerHome"));
const Comments = lazy(() => import("../../components/Comments/Comments"));
const Calculator = lazy(() => import("../../components/Calculator/Calculator"));
const WhyUs = lazy(() => import("../../components/WhyUs/WhyUs"));
const Rates = lazy(() => import("../../components/Rates/Rates"));

const Home = () => {

    //variables
    const {t} = useTranslation()

    //functions
    changeTitle(t("titleHomePage"))

    return (
        <div className={styles.container}>
            <BannerHome/>
            <Calculator/>
            <section className={styles.main_section}>
                <div className="inside">
                    <Rates/>
                    <WhyUs/>
                    <Comments/>
                    {/* <News/> */}
                </div>
                <div className={styles.background}/>
            </section>
            <MarqueeSection/>
        </div>
    )
}
export default Home