import {useState, useEffect} from 'react'

import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

//icons
import VipIcon from '../../assets/images/vipIcon.svg'
import WhatsAppIcon from '../../assets/images/whatsAppButton.svg'
import TelegramIcon from '../../assets/images/telegramButton.svg'
import VipModal from "../../modals/VipModal/VipModal";
import {useDispatch, useSelector} from "react-redux";
import {initContactUsData} from "../../redux/actions/changeLanguageActions";

const MainLayout = ({children}) => {

    //variables
    const [modalVip, setModalVip] = useState(false)
    const dispatch = useDispatch()
    const contactUs = useSelector(state => state.contactUs)


    const findValue = (key) => {
        const obj = contactUs.find(item => item.key === key)
        if (obj) {
            return obj.value
        } else {
            return ''
        }
    }
    useEffect(() => {
        dispatch(initContactUsData())
    }, [])
    return (
        <main>
            <Header/>
            {children}
            <Footer/>


            {findValue("whatsapp") &&
                <a href={findValue("whatsapp")} target='_blank'><img src={WhatsAppIcon} className='imgWhatsapp'
                                                                     alt=""/></a>}
            {findValue("telegram").length > 0 &&
                <a href={findValue("telegram")} target='_blank'><img src={TelegramIcon} className='imgTelegram'
                                                                     alt=""/></a>}
            <img src={VipIcon} className='imgVip' alt="" onClick={() => setModalVip(true)}/>

            <VipModal opened={modalVip} setOpened={setModalVip}/>
        </main>
    )
}
export default MainLayout
