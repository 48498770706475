import PageLayout from "../../layouts/PageLayout/PageLayout";
import {useState, useEffect} from 'react'
import FAQItem from "../../components/FAQItem/FAQItem";
import {getFAQs} from "../../services/services";
import Loading from "../../components/Loading/Loading";

const FAQs = () => {

    //variables
    const [loading, setLoading] = useState(true)
    const [faqs, setFAQs] = useState([])

    //functions
    const handleInitData = async () => {
        setLoading(true)
        try {
            const {data} = await getFAQs()
            if (data.success) {
                await setFAQs(data.data)
                console.log(data.data)
                setLoading(false)
            }
        } catch (err) {
            setLoading(false)
            console.log(err)
        }
    }

    useEffect(() => {
        handleInitData()
    }, [])
    return (
        <PageLayout title="titleFAQPage" classNames="publicBackground">
            {loading ? <Loading/> :
                faqs.map(item => (
                    <FAQItem faq={item} key={item.id}/>
                ))}
        </PageLayout>
    )
}
export default FAQs