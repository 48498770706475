export const removeSpace = string => {
    if (string.length > 0) {
        return string.replaceAll(' ', '')
    } else {
        return ''
    }

}
export const toLocal = (number, decimal = 8) => {
    //purify number
    number = number + '';
    if (number === '.') {
        if (decimal) return '0.';
        else return '';
    }

    if (number.startsWith('-')) return 0;

    if (number.toLowerCase().includes('e')) {
        if (parseFloat(number) < 1.0e-7) {
            //zero numbers
            return 0;
        } else number = parseFloat(number) + '';
    }

    number = p2e(number).replace(/[^\d.]|\.{2}|,/gi, '');
    if (number.includes('.')) {
        number = number.split('.')[0] + '.' + (number.split('.')[1] || '');
    }

    if (parseFloat(number)) {
        //separate number
        const int = Separate(parseFloat(number.split('.')[0]));
        if (number.includes('.') && decimal) {
            return int + '.' + (number.split('.')[1].slice(0, decimal) || '');
        } else {
            return int;
        }
    } else {
        return number;
    }
};

const p2e = string => string.replace(/[۰-۹]/g, d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d));
export const S2N = string => parseFloat((string + '').replace(/,/g, ''));

export const marketType2relatedCoin = marketType =>
    marketType.replace(/_TOMAN|_TETHER/, '');

export const prettifyPrice = number => {
    let price = Number.parseFloat(number);
    if (price === 0) {
        return 0;
    }
    if (price >= 1) {
        return price.toFixed(2);
    }
    return parseFloat(price.toPrecision(4));
};

export function vSeparator(
    target,
    repeatCount = 4,
    separator = '-',
    type = 'd',
    regString = '\\d{1,4}',
) {
    //make filter regex
    let replaceString = '[^\\d]';
    replaceString = replaceString.replace(/d/gi, type);
    const regex1 = new RegExp(replaceString, 'g');

    //filter target
    target = target + '';
    target = target.replace(regex1, '');

    if (target) {
        //make separator regex
        regString = regString.replace(/d/gi, type);
        regString = regString.replace(/4/g, repeatCount);
        const regex = new RegExp(regString, 'g');

        //separate
        return target.match(regex).join(separator);
    }
}

export const checkValue = number => {
    const int = parseFloat(number.split('.')[0]);
    if (number.includes('.')) {
        return (int + '.' + (number.split('.')[1].slice(0, 8) || '')).replace(
            /[^\d.]|\.\./gi,
            '',
        );
    } else {
        return number.replace(/[^\d.]|\.\./gi, '');
    }
};

export function Separate(Number) {
    Number += '';
    Number = Number.replace(',', '');
    var x = Number.split('.');
    var y = x[0];
    var z = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(y)) {
        y = y.replace(rgx, '$1' + ',' + '$2');
    }
    return y + z;
}

export function currencySeparator(
    target,
    repeatCount = 3,
    separator = ',',
    type = 'd',
    regString = '\\d{1,3}',
) {
    //make filter regex
    let replaceString = '[^\\d]';
    replaceString = replaceString.replace(/d/gi, type);
    const regex1 = new RegExp(replaceString, 'g');

    //filter target
    target = target + '';
    target = target;
    target = target.replace(regex1, '');
    // console.log('target', target);
    if (target) {
        //make separator regex
        regString = regString.replace(/d/gi, type);
        regString = regString.replace(/3/g, repeatCount);
        const regex = new RegExp(regString, 'g');

        //separate
        return target.match(regex).join(separator);
    }
}

export const toDecimal = (number, decimal = 8) => {
    //purify number
    number = number + '';
    if (number === '.') {
        if (decimal) return '0.';
        else return '';
    }

    if (number.startsWith('-')) return 0;

    if (number.toLowerCase().includes('e')) {
        if (parseFloat(number) < 1.0e-7) {
            //zero numbers
            return 0;
        } else number = parseFloat(number) + '';
    }

    number = p2e(number).replace(/[^\d.]|\.{2}|,/gi, '');
    if (number.includes('.')) {
        number = number.split('.')[0] + '.' + (number.split('.')[1] || '');
    }

    if (parseFloat(number)) {
        //separate number
        const int = parseFloat(number.split('.')[0]);
        if (number.includes('.') && decimal) {
            return int + '.' + (number.split('.')[1].slice(0, decimal) || '');
        } else {
            return int;
        }
    } else {
        return number;
    }
};
export const truncate = (str, len) => {
    if (str.length > len && str.length > 0) {
        let new_str = str + " ";
        new_str = str.substr(0, len);
        new_str = str.substr(0, new_str.lastIndexOf(" "));
        new_str = new_str.length > 0 ? new_str : str.substr(0, len);
        return new_str + "...";
    }
    return str;
};
export const changeTitle = (title) => {
        document.title = `${title}`
}