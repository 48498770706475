import styles from './Footer.module.scss'
import {useTranslation} from "react-i18next";
import {Link} from 'react-router-dom'
import {useSelector} from "react-redux";
import {footerLinks, socialsNetworks} from "./footerLinks";
import config from '../../services/config.json'
import {useState, useEffect} from "react";
import {getDataContactUs} from "../../services/services";
import Loading from "../Loading/Loading";

import i18next from "i18next";
//images
import faLogo from './fa.png'
import enLogo from './en.png'
import locationIcon from './location.svg'
import phoneIcon from './phone.svg'
import logoGold from "./logoGold.png";

const Footer = () => {
    //variables
    const {t} = useTranslation()
    const [loading, setLoading] = useState(true)
    const [dataContact, setDataContact] = useState([])
    const currentLanguage = useSelector(state => state.currentLanguage)

    //functions
    const initData = async () => {
        setLoading(true)
        try {
            const {data} = await getDataContactUs()
            await setDataContact(data.data)
            setLoading(false)
        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    }
    const findAddress = () => {
        const lang = i18next.language.toString();
        const key = `address${lang}`
        const obj = dataContact.find(item => item.key === key)
        if (obj) {
            return obj.value
        } else {
            return ''
        }
    }
    const findValue = (key) => {
        const obj = dataContact.find(item => item.key === key)
        if (obj) {
            return obj?.value
        } else {
            return ''
        }
    }
    useEffect(() => {
        initData()
    }, [])

    return (
        <footer className={styles.footer}>
            {loading ? <Loading color='var(--bg)'/> :
                <div className="inside">
                    <div className={styles.inside}>
                        <div className={styles.container_logo}>
                            <Link to="/">
                                <img src={logoGold} alt="" className={styles.logo}/>
                            </Link>
                            <img src={currentLanguage === 'fa' ? faLogo : enLogo} className={styles.text_logo} alt=""/>
                            <p className={styles.transactions}>{t("transactions")}</p>
                        </div>
                        <div className={styles.category}>
                            <p className={styles.title}>{t("quickAccess")}</p>
                            {footerLinks.map(link => (
                                <Link className={styles.links} key={link.id} to={link.path}>{t(link.title)}</Link>
                            ))}
                            {/* <a className={styles.links} href={config.blogAddress} target="_blank">{t("News")}</a> */}

                        </div>
                        <div className={styles.category}>
                            <p className={styles.title}>{t("contactUs")}</p>
                            <div className={styles.info}>
                                <img src={locationIcon} alt=""/>
                                <p>{findAddress()}</p>
                            </div>
                            {dataContact?.find(item => item.key === 'phone')?.value?.split(',')?.map(item => (
                                <div className={styles.info}>
                                    <img src={phoneIcon} alt=''/>
                                    <a
                                        href={`tel:${item}`}>{item}</a>
                                </div>
                            ))}
                        </div>

                        <div className={styles.category}>
                            <p className={`${styles.title} ${styles.title_last}`}>{t("secondsOnSocialNetworks")}</p>
                            <div className={styles.container_icons}>
                                {socialsNetworks.map(item => (
                                    <a href={findValue(item.name)} key={item.id} target='_blank'><img
                                        src={item.image}
                                        alt=""/></a>
                                ))}

                            </div>
                        </div>
                    </div>
                </div>
            }
        </footer>
    )
}
export default Footer