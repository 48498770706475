import styles from './ContactUs.module.scss'
import PageLayout from "../../layouts/PageLayout/PageLayout";
import {useTranslation} from "react-i18next";
import {useState, useEffect} from "react";
import parse from "html-react-parser";

//icons
import {ReactComponent as EmailIcon} from '../../assets/images/emailIcon.svg'
import {ReactComponent as LocationIcon} from '../../assets/images/locationIcon.svg'
import {ReactComponent as ShopIcon} from '../../assets/images/shopIcon.svg'
import {ReactComponent as FacebookIcon} from '../../assets/images/facebookIcon.svg'
import {ReactComponent as TelegramIcon} from '../../assets/images/telegramIcon.svg'
import {ReactComponent as WhatsAppIcon} from '../../assets/images/whatsappIcon.svg'
import {ReactComponent as TwitterIcon} from '../../assets/images/twitterIcon.svg'
import {ReactComponent as InstagramIcon} from '../../assets/images/instagramIcon.svg'

import {getDataContactUs} from "../../services/services";
import {useSelector} from "react-redux";

const ContactUs = () => {

    // variables
    const {t} = useTranslation()
    const [loading, setLoading] = useState(true)
    const [contactUs, setContactUs] = useState([])
    const currentLanguage = useSelector(state => state.currentLanguage)

    //functions
    const handleInitData = async () => {
        setLoading(true)
        try {
            const {data} = await getDataContactUs()
            if (data.success) {
                await setContactUs(data.data)
                setLoading(false)
            }
        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    }
    const findValue = (key) => {
        const obj = contactUs.find(item => item.key === key)
        if (obj) {
            return obj.value
        } else {
            return ''
        }
    }
    useEffect(() => {
        handleInitData()
    }, [])

    const persiondata =contactUs.find(i => i.key === 'contactusfa') 

    console.log(contactUs)
    console.log(persiondata)
    return (
        <PageLayout title='ContactUs' classNames='contactUsBackground'>
            <div className={styles.top}>
                <div className={styles.section_info}>
                    {contactUs?.find(item => item.key === 'phone')?.value?.split(',')?.map(item => (
                        <div className={styles.info}>
                            <ShopIcon className={styles.icon}/>
                            <a href={`tel:${item}`}>{item}</a>
                        </div>
                    ))}
                    {contactUs?.find(item => item.key === 'email')?.value?.split(',')?.map(item => (
                        <div className={styles.info}>
                            <EmailIcon className={styles.icon} />
                            <a href={`tel:${item}`}>{item}</a>
                        </div>
                    ))}
                 
                        <div className={styles.info}>
                            <LocationIcon className={styles.icon} />
                        {contactUs?.find(item => item.key === "addressen")?.value?.split(',')?.map(item => (
                            <a href={`tel:${item}`}>{item}</a>
                        ))}
                        </div>
                
         
                    <p className={styles.title}>{t("exchangeInSocial")}</p>
                    <div className={styles.container_socials}>
                        <a target='_blank' href={findValue("telegram")}><TelegramIcon/></a>
                        <a target='_blank' href={findValue("instagram")}><InstagramIcon/></a>
                        <a target='_blank' href={findValue("facebook")}><FacebookIcon/></a>
                        <a target='_blank' href={findValue("whatsapp")}><WhatsAppIcon/></a>
                        <a target='_blank' href={findValue("twitter")}><TwitterIcon/></a>
                    </div>
                </div>

                <div className={styles.container_map}>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3314.0603002933603!2d150.98780931520378!3d-33.836555524817356!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd255ed85d19080b2!2zMzPCsDUwJzExLjYiUyAxNTDCsDU5JzI0LjAiRQ!5e0!3m2!1sen!2s!4v1660458476944!5m2!1sen!2s"
                        className='map'
                        allowFullScreen=""
                        loading="lazy"></iframe>
                </div>
            </div>
            <div className={styles.content}>{parse(findValue(`contactus${currentLanguage}`))}</div>
        </PageLayout>
    )
}
export default ContactUs