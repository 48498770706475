import {Route, Routes} from 'react-router-dom'

import MainLayout from "../layouts/MainLayout/MainLayout";

//pages
import Home from "../pages/Home/Home";
import FAQs from "../pages/FAQs/FAQs";
import ContactUs from "../pages/ContactUs/ContactUs";
import Chart from "../pages/Chart/Chart";
import Rule from "../pages/Rule/Rule";
import AustraliaToIran from "../pages/Remittances/AustraliaToIran/AustraliaToIran";
import IranToAustralia from "../pages/Remittances/IranToAustralia/IranToAustralia";
import Trasnferbetween from "../pages/Remittances/Trasnferbetween/Trasnferbetween"
import AboutUs from "../pages/AboutUs/AboutUs";

const SaniehMoneyExchange = () => {

    return (
        <MainLayout>
            <Routes>
                <Route path='/' exact element={<Home/>}/>
                <Route path='/faq' element={<FAQs/>}/>
                <Route path='/chart' element={<Chart/>}/>
                <Route path='/rule' element={<Rule/>}/>
                <Route path='/about-us' element={<AboutUs/>}/>
                <Route path='/contact-us' element={<ContactUs/>}/>
                <Route path='/iran-to-australia' element={<IranToAustralia/>}/>
                <Route path='/australia-to-iran' element={<AustraliaToIran/>}/>
                <Route path='/Trasnferbetween' element={<Trasnferbetween />} />
            </Routes>
        </MainLayout>
    )
}
export default SaniehMoneyExchange