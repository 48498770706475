import styles from './Header.module.scss'
import TopHeader from "../TopHeader/TopHeader";
import Navbar from "../Navbar/Navbar";

const Header = () => {
    return (
        <header className={styles.header}>
            <div className="inside">
                <TopHeader/>
                <Navbar/>
            </div>
        </header>
    )
}
export default Header