import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import i18next from "i18next";
import {changeLanguage} from "../redux/actions/changeLanguageActions";

const useDetectLanguage = () => {

    const [language, setLanguage] = useState('en');
    const currentLanguage = useSelector(state => state.currentLanguage)
    const dispatch = useDispatch()

    const handleChangeLanguage = async (strLanguage) => {
        await window.localStorage.setItem('language', strLanguage)
        dispatch(changeLanguage(strLanguage))
        await setLanguage(strLanguage)
        await i18next.changeLanguage(strLanguage)
    }

    const setLocalLanguage = async () => {
        const localLanguage = window.localStorage.getItem('language')
        if (localLanguage) {
            await setLanguage(localLanguage)
            await i18next.changeLanguage(localLanguage)
            dispatch(changeLanguage(localLanguage))
        }
    }
    useEffect(() => {
        setLocalLanguage()
    }, [])

    useEffect(() => {
        if (currentLanguage === 'fa') {
            document.body.dir = 'rtl'
        } else {
            document.body.dir = 'ltr'
        }
    }, [currentLanguage])
    return [language, handleChangeLanguage]
}
export default useDetectLanguage
