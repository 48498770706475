import styles from './VipModal.module.scss'
import styleInput from '../../components/MyInput/MyInput.module.scss'
import {Modal, Menu} from '@mantine/core';
import closeModal from '../../assets/images/closeModal.svg'
import {useTranslation} from "react-i18next";
import MyInput from "../../components/MyInput/MyInput";
import {useState, useEffect} from 'react'

import user from './user.svg'
import mobile from './mobile.svg'
import emailIcon from './email.svg'
import earthIcon from './earth.svg'
import {getSpecialRates, registerSpecialPrice} from "../../services/services";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import Loading from "../../components/Loading/Loading";

const VipModal = ({opened, setOpened}) => {

    //variables
    const {t} = useTranslation()
    const [isDisable, setIsDisable] = useState(false);
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [name_family, setNameFamily] = useState("")
    const [rate_id, setRateId] = useState()
    const [countryName, setCountryName] = useState("")
    const [loading, setLoading] = useState(true)
    const [rates, setRates] = useState([])
    const currentLanguage = useSelector(state => state.currentLanguage)

    //functions
    const handleReset = () => {
        setEmail("")
        setPhone("")
        setNameFamily("")
        setRateId(-1)
    }
    const handleRegister = async () => {
        setIsDisable(true)
        setLoading(true)
        const objUser = {
            email, phone, name_family, rate_id
        }
        try {
            const {data} = await registerSpecialPrice(objUser)
            if (data.success) {
                toast.success(`${data.message}`)
                setIsDisable(false)
                setOpened(false)
                handleReset()

            } else {
                toast.error(`${data.message}`)
                setIsDisable(false)
            }
        } catch (err) {
            console.log(err)
            if (err.data.message.phone) {
                toast.error(`${err.data.message.phone[0]}`)
            } else {
                toast.error(`${err.data.message}`)
            }
            setIsDisable(false)
        }
    }
    const initData = async () => {
        setLoading(true)
        try {
            const {data} = await getSpecialRates()
            await setRates(data.data)
            setLoading(false)
        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    }
    const handleClickItem = async (id, string) => {
        await setRateId(id)
        await setCountryName(string)
    }
    useEffect(() => {
        initData()
    }, [])
    useEffect(() => {
        setCountryName(t("Select country"))
        initData()
        setRateId(-1)
        setLoading(false)
    }, [opened])
    return (
        <Modal centered={true} opened={opened} classNames={{
            modal: styles.modal
        }}
               onClose={() => setOpened(false)} withCloseButton={false}>
            <div className={styles.header}>
                <div/>
                <p>{t("Get special rates")}</p>
                <img src={closeModal} onClick={() => setOpened(false)} alt=""/>
            </div>
            <div className={styles.content}>
                <MyInput image={user} placeholder={t("Name")} value={name_family} handleChange={setNameFamily}/>
                <MyInput image={emailIcon} placeholder={t("Email")} type='email' value={email} handleChange={setEmail}/>
                <Menu width={200}>
                    <Menu.Target>
                        <div className={styleInput.container}>
                            <div className={styleInput.container_icon}>
                                <img src={earthIcon} alt=""/>
                            </div>
                            <p className={styles.country_name}>{countryName}</p>
                        </div>
                    </Menu.Target>
                    <Menu.Dropdown>
                        {rates.map(item => (
                            <Menu.Item
                                onClick={() => handleClickItem(item.id, currentLanguage === 'en' ? `${item.country_from_en}  to ${item.country_to_en}` : `${item.country_from_fa} به ${item.country_to_fa}`)}
                                key={item.id}>{currentLanguage === 'en' ? `${item.country_from_en}  to ${item.country_to_en}` : `${item.country_from_fa} به ${item.country_to_fa}`}</Menu.Item>
                        ))}
                    </Menu.Dropdown>
                </Menu>

                <MyInput image={mobile} placeholder={t("placeHolderMobileVIP")} type='tel' classNames='mb0' value={phone}
                         handleChange={setPhone}/>
                <button disabled={isDisable || email === "" || phone === "" || name_family === "" || rate_id === -1}
                        onClick={() => handleRegister()} className={styles.button}>{loading ? <Loading/> :
                    <p>{t("Membership")}</p>}</button>
            </div>
        </Modal>
    )
}
export default VipModal