import styles from './Chart.module.scss'
import PageLayout from "../../layouts/PageLayout/PageLayout";
import TradingViewWidget from 'react-tradingview-widget';
import Themes from 'react-tradingview-widget'

const Chart = () => {
    return (
        <PageLayout title='Chart' classNames='publicBackground'>
            <div className={styles.container}>
                <TradingViewWidget
                    symbol="NASDAQ:AAPL"
                    theme={Themes.DARK}
                    locale="en"
                    autosize
                />
            </div>
        </PageLayout>
    )
}
export default Chart