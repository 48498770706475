import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {getAboutUs} from "../../services/services";
import PageLayout from "../../layouts/PageLayout/PageLayout";
import Loading from "../../components/Loading/Loading";
import parse from "html-react-parser";

const AboutUs = () => {
    //variables
    const [loading, setLoading] = useState(true)
    const [about, setAbout] = useState()
    const currentLanguage = useSelector(state => state.currentLanguage)

    //functions
    const initData = async () => {
        setLoading(true)
        try {
            const {data} = await getAboutUs()
            if (data.success) {
                await setAbout(data.data[0])
                setLoading(false)
            }
        } catch (err) {
            setLoading(false)
            console.log(err)
        }
    }
    useEffect(() => {
        initData()
    }, [])
    return (
        <PageLayout title="About Us" classNames="publicBackground">
            {loading ? <Loading/>:parse(currentLanguage==='en' ? about.text_en : about.text_fa)}
        </PageLayout>
    )
}
export default AboutUs