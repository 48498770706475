import styles from './MyInput.module.scss'

const MyInput = ({
                     type = "text", classNames, image, placeholder,
                     value,
                     handleChange,
                 }) => {
    return (
        <div className={`${styles.container} ${classNames}`}>
            <div className={styles.container_icon}>
                <img src={image} alt=""/>
            </div>
            <input type={type} value={value}
                   onChange={e => handleChange(e.target.value)} className={styles.input} placeholder={placeholder}/>
        </div>
    )
}
export default MyInput