import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import translationEn from '../assets/locales/en/en.json'
import translationFa from '../assets/locales/fa/fa.json'

const resources = {
    fa: {
        translation: translationFa
    },
    en: {
        translation: translationEn
    }
}

i18n.use(initReactI18next).init({
    resources,
    lng: 'fa',
    keySeparator: false,
    interpolation: {
        escapeValue: false
    }
})

export default i18n;