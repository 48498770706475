import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './containers/App';

import {Provider} from 'react-redux'
import {store} from "./redux/store/store";
import {BrowserRouter} from 'react-router-dom'

import './helper/i18'

// Swiper Styles
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import "swiper/css/effect-fade";
//styles
import './assets/styles/colors.scss'
import './assets/styles/styles.scss'
import './assets/styles/fontIranYekan.scss'
import './assets/styles/toastify.scss'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
        <Provider store={store}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </Provider>
);

