import telegramIcon from './telegram.svg'
import whatsAppIcon from './whatsup.svg'
import instagramIcon from './instagram.png'
import twitterIcon from './twitter.svg'

export const footerLinks = [
    {id: 0, title: "FAQ", path: "/faq"},
    {id: 1, title: "Contact Us", path: "/contact-us"},
    {id: 1, title: "About Us", path: "/about-us"},

]
export const socialsNetworks = [
    {id: 0, image: telegramIcon, name: 'telegram'},
    {id: 1, image: whatsAppIcon, name: 'whatsapp'},
    {id: 2, image: instagramIcon, name: 'instagram'},
    {id: 3, image: twitterIcon, name: 'twitter'},
]