import {Link} from 'react-router-dom'

import styles from './Navbar.module.scss'
import logo from '../../assets/images/logo.png'
import RemittancesMenu from "../RemittancesMenu/RemittancesMenu";
import {navItems} from "./navItems";
import {useTranslation} from "react-i18next";
import config from '../../helper/config.json'

const Navbar = () => {

    //variables
    const {t} = useTranslation()

    return (
        <nav className={styles.navbar}>
            <Link to="/">
                <img src={logo} alt="" className={styles.logo}/>
            </Link>
            <RemittancesMenu/>
            {navItems.map(item => (
                <Link className={styles.nav_item} key={item.id} to={item.path}>{t(item.title)}</Link>
            ))}
            <a href={config.blogAddress} className={styles.nav_item} target='_blank'>{t("Blog")}</a>
        </nav>
    )
}
export default Navbar