import {ToastContainer} from "react-toastify";

import SaniehMoneyExchange from "./SaniehMoneyExchange";

const App = () => {
    return (
        <>
            <SaniehMoneyExchange/>
            <ToastContainer closeOnClick={true} position="top-right"/>
        </>
    )
}
export default App