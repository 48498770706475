import styles from './FAQItem.module.scss'
import {useSelector} from "react-redux";
import {Collapse} from '@mantine/core';
import {useState} from 'react'
import {ReactComponent as ArrowFAQ} from '../../assets/images/arrowFAQ.svg'

const FAQItem = ({faq}) => {

    // variables
    const currentLanguage = useSelector(state => state.currentLanguage)
    const [opened, setOpened] = useState(false);

    return (
        <div className={styles.container}>
            <div onClick={() => {setOpened(!opened)}} className={styles.question_section}>
                <p>{currentLanguage === 'en' ? faq?.questionEn : faq?.questionFa}</p>
                <ArrowFAQ className={opened ? `${styles.arrow} ${styles.active}` : styles.arrow}/>
            </div>
            <Collapse in={opened}>
                <p>{currentLanguage === 'en' ? faq?.answerEn : faq?.answerFa}</p>
                <p className={styles.answer}>{currentLanguage === 'en' ? faq?.answerEn : faq?.answerFa}</p>
            </Collapse>
        </div>
    )
}
export default FAQItem