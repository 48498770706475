import {useState, useEffect} from 'react'
import styles from './MobileMenu.module.scss'
import {Burger, Drawer, Collapse} from '@mantine/core';
import {mobileList} from "./mobileList";
import {useTranslation} from "react-i18next";
import {useNavigate} from 'react-router-dom'
import * as GrIcons from "react-icons/gr";

const MobileMenu = () => {

    //variables
    const [opened, setOpened] = useState(false)
    const [collapse, setCollapse] = useState(false)
    const {t} = useTranslation()
    const navigate = useNavigate()
    // functions
    const handleClick = (path) => {
        navigate(path)
        setCollapse(false)
        setOpened(false)
    }

    useEffect(() => {
        if (!opened) {
            setCollapse(false)
        }
    }, [opened])
    return (
        <>
            <div className={styles.container}>
                <Burger color="var(--gold)"
                        opened={opened}
                        onClick={() => setOpened((o) => !o)}
                />
            </div>
            <Drawer
                opened={opened}
                onClose={() => setOpened(false)}
                padding="xs"
                position='right'
                size="xs"
            >
                <div className={styles.menu_item} onClick={() => setCollapse((o) => !o)}>
                    <p>{t("Remittances")}</p>
                </div>
                <Collapse in={collapse}>
                    <div className={styles.menu_item} onClick={() => handleClick('/iran-to-australia')}>
                        <p>{t("Iran to Australia")}</p>
                        <GrIcons.GrMoney className='iconSidebar'/>
                    </div>
                    <div className={styles.menu_item} onClick={() => handleClick('/australia-to-iran')}>
                        <p>{t("Australia to Iran")}</p>
                        <GrIcons.GrMoney className='iconSidebar'/>
                    </div>
                </Collapse>
                {mobileList.map(item => (
                    <div className={styles.menu_item} key={item.id} onClick={() => handleClick(item.path)}>
                        <p>{t(item.title)}</p>
                        {item.icon}
                    </div>
                ))}
            </Drawer>

        </>

    )
}
export default MobileMenu