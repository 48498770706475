import styles from './TopHeader.module.scss'
import ChangeLanguage from "../ChangeLanguage/ChangeLanguage";
import MobileMenu from "../MobileMenu/MobileMenu";

const TopHeader = () => {

    return (
        <div className={styles.topHeader}>
            <ChangeLanguage/>
            <MobileMenu/>
        </div>
    )
}
export default TopHeader