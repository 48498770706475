import styles from './Loading.module.scss'
import {Default} from 'react-awesome-spinners'
import {useTranslation} from "react-i18next";

const Loading = ({color = "var(--lightPurple)"}) => {

    const {t} = useTranslation()

    return (
        <div className={styles.container}>
            <Default color={color}/>
            <p style={{color}}>{t("loadingMessage")}</p>
        </div>
    )
}
export default Loading