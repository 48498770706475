import styles from './MarqueeSection.module.scss'
import Marquee from "react-fast-marquee";
import {useState, useEffect} from 'react'
import {currencyPairs} from "../../services/services";

const MarqueeSection = () => {

    //variables
    const [usd, setUSD] = useState([])
    const [eur, setEUR] = useState([])
    const [loading, setLoading] = useState(true)

    // functions
    const initData = async () => {
        setLoading(true)
        var arrUSD = [];
        var arrEUR = [];

        try {
            const {data} = await currencyPairs()
            data.data.USD.map((item, index) => {
                const obj = {id: index, name: "", value: ""};
                const key = Object.keys(item);
                const val = Object.values(item);
                obj.name = key[0];
                obj.value = val[0];
                arrUSD.push(obj);
            });

            data.data.EUR.map((item, index) => {
                const obj = {id: index, name: "", value: ""};
                const key = Object.keys(item);
                const val = Object.values(item);
                obj.name = key[0];
                obj.value = val[0];
                arrEUR.push(obj);
            });
            await setUSD(arrUSD)
            await setEUR(arrEUR)
            setLoading(false)
        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    }

    useEffect(() => {
        initData()
    }, [])

    return (
        <section dir='rtl' className={styles.top_marquee}>
            <Marquee
                className={styles.container}
                gradient={false}
                play={true}
                pauseOnHover={true}
            >
                {usd.map((item, index) => (
                    <p key={index} dir="ltr" className={styles.text}>
                        {item.name} = <span>{item.value}</span>
                    </p>
                ))}
                {eur.map((item, index) => (
                    <p key={index} dir="ltr" className={styles.text}>
                        {item.name} = <span>{item.value}</span>
                    </p>
                ))}
            </Marquee>
        </section>
    )
}
export default MarqueeSection