import styles from './ChangeLanguage.module.scss'
import useDetectLanguage from "../../hooks/useDetectLanguage";
//images
import iranFlag from './../../assets/images/flags/iran.png'
import englishFlag from '../../assets/images/flags/english.png'

const ChangeLanguage = () => {

    //variables
    const [language, setLanguage] = useDetectLanguage()

    //functions
    const handleChangeLanguage = async (lang) => {
        await setLanguage(lang)
    }
    return (
        <div className={styles.changeLanguage}>
            <img src={englishFlag}
                 className={language === 'en' ? styles.active : ''}
                 onClick={() => handleChangeLanguage('en')} alt=""
            />
            <img src={iranFlag}
                 className={language === 'fa' ? styles.active : ''}
                 onClick={() => handleChangeLanguage('fa')} alt=""
            />
        </div>
    )
}
export default ChangeLanguage