import * as AiIcons from "react-icons/ai";
import * as BsIcons from "react-icons/bs";
import * as MdIcons from "react-icons/md";
import * as HiIcons from "react-icons/hi";

export const mobileList = [
    {id: 0, title: 'Home', path: '/', icon: <AiIcons.AiFillHome className='iconSidebar'/>},
    {id: 1, title: 'Chart', path: '/chart', icon: <BsIcons.BsFillBarChartFill className='iconSidebar' />},
    {id: 2, title: 'AboutUs', path: '/about-us', icon: <HiIcons.HiInformationCircle className='iconSidebar' />},
    {id: 3, title: 'ContactUs', path: 'contact-us', icon: <MdIcons.MdContactPhone className='iconSidebar' />},
    {id: 4, title: 'FAQ', path: 'faq', icon: <BsIcons.BsFillQuestionSquareFill className='iconSidebar' />},
    {id: 5, title: 'Rules', path: '/rule', icon: <BsIcons.BsFillFileEarmarkRuledFill className='iconSidebar' />},
]