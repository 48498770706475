import PageLayout from "../../layouts/PageLayout/PageLayout";
import {useState, useEffect} from 'react'
import {getRules} from "../../services/services";
import {useSelector} from "react-redux";
import parse from "html-react-parser";
import Loading from "../../components/Loading/Loading";

const Rule = () => {

    //variables
    const [loading, setLoading] = useState(true)
    const [about, setAbout] = useState()
    const currentLanguage = useSelector(state => state.currentLanguage)

    //functions
    const initData = async () => {
        setLoading(true)
        try {
            const {data} = await getRules()
            if (data.success) {
                await setAbout(data.data)
                setLoading(false)
            }
        } catch (err) {
            setLoading(false)
            console.log(err)
        }
    }
    useEffect(() => {
        initData()
    }, [])
    return (
        <PageLayout title="Rules" classNames="publicBackground">
            {loading ? <Loading/>:parse(currentLanguage==='en' ? about.text_en : about.text_fa)}
        </PageLayout>
    )
}
export default Rule