import {useTranslation} from "react-i18next";
import {Menu} from '@mantine/core';
import {useNavigate} from 'react-router-dom'
import {ReactComponent as DropDownArrow} from '../../assets/images/dropDownArrow.svg'
import styles from './RemittancesMenu.module.scss'

const RemittancesMenu = () => {

    //variables
    const {t} = useTranslation()
    const navigate = useNavigate()
    return (
        <Menu classNames={{dropdown: styles.dropdown}} trigger="hover" openDelay={100} closeDelay={400}>
            <Menu.Target>
                <div className={styles.container}>
                    <p className={styles.title}>{t("Remittances")}</p>
                    <DropDownArrow className={styles.arrow}/>
                </div>
            </Menu.Target>
            <Menu.Dropdown>
                <Menu.Item onClick={()=>navigate("/iran-to-australia")}>{t("Iran to Australia")}</Menu.Item>
                <Menu.Item onClick={()=>navigate("/australia-to-iran")}>{t("Australia to Iran")}</Menu.Item>
                <Menu.Item onClick={() => navigate("/Trasnferbetween")}>{t("Trasnferbetween")}</Menu.Item>
            </Menu.Dropdown>
        </Menu>
    )
}
export default RemittancesMenu